import Vue from 'vue/dist/vue.esm'
import Vue2TouchEvents from 'vue2-touch-events'
import ExpandableItem from '../javascripts/vue-components/expandable-item/ExpandableItem'

Vue.prototype.$eventHub = new Vue()

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(Vue2TouchEvents)

  new Vue({
    el: '#vue-app',
    components: { ExpandableItem }
  })
})
